import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Layout from '../components/Layout'
import { HeroSimpleCentered } from '../components/Global/Hero'
import { YellowButton, BlackButton } from '../components/Global/Button'
import SEO from '../components/Global/SEO'
import { InlineLink } from '../components/Global/Link'

const FleetPage = ({ location }) => {

  const { seoImg, sclass, eclass, vclass } = useStaticQuery(graphql`
    query {
      seoImg: file(relativePath: { eq: "mercedes-interior.jpg" }) {
        childImageSharp {
          fixed(quality: 90, width: 1200, height: 630) {
            src
          }
        }
      }
      sclass: file(relativePath: { eq: "mercedes-s-class.webp" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      eclass: file(relativePath: { eq: "mercedes-e-class.webp" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vclass: file(relativePath: { eq: "mercedes-v-class.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 980) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  
  return (
    <Layout>

      <SEO
        title="Our Fleet of Chauffeur Me Executive &amp; Luxury Vehicles for Hire"
        description="Chauffeur Me provide chauffeur-driven Mercedes and other executive and luxury vehicles, which may be hired for travel throughout the Island of Ireland."
        image={seoImg.childImageSharp.fixed.src}
        pathname={location.pathname}
      />

      <HeroSimpleCentered>
        <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
          Luxury
          <span className="text-yellow-300"> Fleet</span>
        </h2>
        <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
          Chauffeur Me provide chauffeur-driven Mercedes and other executive and luxury vehicles, which may be hired for travel throughout the Island of Ireland.
        </p>
        <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
          <YellowButton to="/book-now" className="flex items-center justify-center rounded-sm shadow sm:mr-2 mb-4 sm:mb-0">Book Now</YellowButton>
          <BlackButton to="/about" className="flex items-center justify-center rounded-sm shadow sm:ml-2">About Us</BlackButton>
        </div>
      </HeroSimpleCentered>

      <div className="pb-16 overflow-hidden lg:pb-24">
        <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
          <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="svg-pattern-squares-1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#svg-pattern-squares-1)" />
          </svg>

          <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="relative">
              <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                Mercedes-Benz E-Class
              </h4>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                The sophisticated Mercedes-Benz E-Class is the perfect choice for many clients seeking an <InlineLink to="/services/executive-airport-transfers">executive airport transfer</InlineLink>, <InlineLink to="/tours">private chauffeured tour</InlineLink> or <InlineLink to="/services/professional-wedding-chauffeur">wedding car service</InlineLink>. Our chauffeur driven Mercedes E-Class is aslo often the luxury vehicle of choice for <InlineLink to="/services/corporate-travel">corporate clients</InlineLink> and our luxury chauffeur service which guarantees exceptional quality we all expect from Mercedes-Benz.
              </p>
              <div className="mt-8 overflow-hidden">
                <div className="-mx-8 -mt-8 flex flex-wrap">
                  <div className="px-8 pt-8">
                    <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                      3
                    </p>
                    <p className="text-base leading-6 font-medium text-gray-500">
                      Passengers
                    </p>
                  </div>
                  <div className="px-8 pt-8">
                    <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                      2
                    </p>
                    <p className="text-base leading-6 font-medium text-gray-500">
                      Large Cases
                    </p>
                  </div>
                  <div className="px-8 pt-8">
                    <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                      3
                    </p>
                    <p className="text-base leading-6 font-medium text-gray-500">
                      Hand Luggage
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0">
              <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                <defs>
                  <pattern id="svg-pattern-squares-2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                    <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width="784" height="404" fill="url(#svg-pattern-squares-2)" />
              </svg>
              <Img className="relative mx-auto" fluid={eclass.childImageSharp.fluid} alt="Chaufeur Me Executive Mercedes E-Class" />
            </div>
          </div>

          <svg className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="svg-pattern-squares-3" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#svg-pattern-squares-3)" />
          </svg>

          <div className="relative mt-12 sm:mt-16">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="lg:col-start-2">
                <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Mercedes-Benz S-Class
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  Our chauffeur driven Mercedes-Benz S-Class is one of the most requested and in demand luxury vehicles due to its spacious interior, stunning exterior looks and world-class engineering. The Mercedes-Benz S-Class will show how your chauffeur service with Chauffeur Me is a unique occasion and one that you will treasure forever.
                </p>
                <div className="mt-8 overflow-hidden">
                  <div className="-mx-8 -mt-8 flex flex-wrap">
                    <div className="px-8 pt-8">
                      <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                        4
                      </p>
                      <p className="text-base leading-6 font-medium text-gray-500">
                        Passengers
                      </p>
                    </div>
                    <div className="px-8 pt-8">
                      <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                        2
                      </p>
                      <p className="text-base leading-6 font-medium text-gray-500">
                        Large Cases
                      </p>
                    </div>
                    <div className="px-8 pt-8">
                      <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                        3
                      </p>
                      <p className="text-base leading-6 font-medium text-gray-500">
                        Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1 px-10">
                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                  <defs>
                    <pattern id="svg-pattern-squares-4" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#svg-pattern-squares-4)" />
                </svg>
                <Img className="relative mx-auto" fluid={sclass.childImageSharp.fluid} alt="Chauffeur Me luxury Mercedes S-Class." />
              </div>
            </div>
          </div>
        
          <div className="relative mt-12 sm:mt-16">
            <div className="relative lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                  Mercedes-Benz V-Class
                </h4>
                <p className="mt-3 text-lg leading-7 text-gray-500">
                  The perfect luxury vehicle for chauffeur driven <InlineLink to="/services/corporate-travel">executive travel</InlineLink>, financial roadshows, <InlineLink to="/tours">chauffeur tours</InlineLink>, chauffeur services for bridal/groom parties for <InlineLink to="/services/professional-wedding-chauffeur">weddings</InlineLink>, <InlineLink to="/services/executive-airport-transfers">luxury airport transfers</InlineLink> and special events. The Mercedes-Benz V-Class will carry up to 7 passengers in safety, comfort and chauffeur driven luxury. The chauffeur driven Mercedes V-Class will allow you to work, entertain and relax in luxury and style.
                </p>
                <div className="mt-8 overflow-hidden">
                  <div className="-mx-8 -mt-8 flex flex-wrap">
                    <div className="px-8 pt-8">
                      <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                        7
                      </p>
                      <p className="text-base leading-6 font-medium text-gray-500">
                        Passengers
                      </p>
                    </div>
                    <div className="px-8 pt-8">
                      <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                        7
                      </p>
                      <p className="text-base leading-6 font-medium text-gray-500">
                        Large Cases
                      </p>
                    </div>
                    <div className="px-8 pt-8">
                      <p className="text-2xl leading-8 font-extrabold text-yellow-300 sm:text-3xl sm:leading-9">
                        7
                      </p>
                      <p className="text-base leading-6 font-medium text-gray-500">
                        Hand Luggage
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0 px-4">
                <svg className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
                  <defs>
                    <pattern id="svg-pattern-squares-2" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                      <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
                    </pattern>
                  </defs>
                  <rect width="784" height="404" fill="url(#svg-pattern-squares-2)" />
                </svg>
                <Img className="relative mx-auto" fluid={vclass.childImageSharp.fluid} alt="Chauffeur Me Mercedes V-Class." />
              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default FleetPage